import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

const IndexPage = props => {
  const users = props.data.allBtswebViewtypeJson.edges

  return (
    <Layout>
      <table className="usa-table-borderless">
        <tr>
          <th scope="col">
            <b>Title</b>
          </th>
          <th>Author</th>
          <th>Status</th>
        </tr>
        {users.map((user, i) => {
          const userData = user.node
          return (
            <tr key={i}>
              <th>{userData.Title}</th>
              <th>{userData.Author}</th>
              <th>{userData.Status}</th>
            </tr>
          )
        })}
      </table>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query AllDocuments {
    allBtswebViewtypeJson(filter: { ViewType: { eq: "User" } }) {
      edges {
        node {
          ID
          Title
          Caption
          Author
          ApprovalStatus
          Status
          Type
          ViewType
        }
      }
    }
  }
`
